import _round from 'lodash/round';
import { DownloadProgressProps } from '../../Types';
import saveBlob from './saveBlob';

interface MyProps {
    srcUrl: string;
    dstFilename: string;
    updateProgress: (progress: DownloadProgressProps) => void;
    onLoaded?: () => void;
}

export default ({ srcUrl, dstFilename, updateProgress, onLoaded }: MyProps) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('progress', (event) => {
        updateProgress({
            inProgress: true,
            value: _round((100 * event.loaded) / event.total),
        });
    });

    xhr.addEventListener('loadstart', (event) => {
        updateProgress({
            inProgress: true,
            value: 0,
        });
    });

    xhr.addEventListener('loadend', async (event) => {
        updateProgress({
            inProgress: false,
            value: 0,
        });
        if (onLoaded) {
            onLoaded();
        }
    });

    xhr.responseType = 'blob';
    xhr.onload = (event) => {
        const blob = xhr.response;
        saveBlob(blob, dstFilename);
    };
    xhr.open('GET', srcUrl);
    xhr.send();
};
