import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { InlinePaymentProps } from '../../Types';
import getCurrency from '../../utils/currency/getCurrency';
import getPrice from '../../utils/currency/getPrice';
import parsePrice from '../../utils/currency/parsePrice';
import usePaypalButton from '../../utils/hooks/usePaypalButton';
import css from './index.module.scss';

export default ({
    country,
    product,
    onSuccess,
    onProcessingChange,
    getGiftingDetails,
}: InlinePaymentProps) => {
    const paypalRef = useRef(null);
    const [processingPayment] = usePaypalButton({
        productId: product?.id,
        currency: product ? getCurrency(product.price, country).toUpperCase() : undefined,
        total: product ? getPrice(product.price, country) : undefined,
        onSuccess,
        divRef: paypalRef,
        showPayment: true,
        getGiftingDetails,
    });

    useEffect(() => {
        onProcessingChange(processingPayment);
    }, [processingPayment]); // eslint-disable-line

    return (
        <div className={css.body}>
            <h6 className={css.proforma}>
                Your account will be charged: {parsePrice(product.price, country)}
            </h6>
            <Col className={css.paymentButtons}>
                <div ref={paypalRef} />
            </Col>
        </div>
    );
};
