import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../components/Firebase';
import { DigitalBookProps } from '../../Types';

export default (bookId: string) => {
    const { dbDigitalBooks } = useContext(FirebaseContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [product, setProduct] = useState<DigitalBookProps>();

    useEffect(() => {
        if (!bookId || !dbDigitalBooks) {
            return;
        }

        setIsLoading(true);
        dbDigitalBooks
            .where('published', '==', 'true')
            .get()
            .then((allDocs: any) => {
                allDocs.forEach((doc: any) => {
                    if (doc && doc.exists) {
                        if (doc.data().id === bookId) {
                            setProduct(doc.data());
                        }
                    }
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [bookId, dbDigitalBooks]);

    return [isLoading, product] as const;
};
