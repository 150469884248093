import axios from 'axios';
import Cookies from 'universal-cookie';
import { getUserCountry } from '.';
import constants from '../../assets/constants';
import { GeoLocationResponseProps } from '../../Types';
import getCountryDetails, { DEFAULT_COUNTRY } from './getCountryDetails';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_APIKEY;
export default async (): Promise<void> => {
    const cookies = new Cookies();

    // delete any old cookie
    for (const cKey of constants.OLD_COUNTRY_COOKIES) {
        cookies.remove(cKey);
    }

    const userCountry = cookies.get(constants.COUNTRY_COOKIE_KEY);
    if (userCountry) {
        return Promise.resolve();
    }

    const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_MAP_API_KEY}`;
    const res = await axios.post(url);
    const location = res.data as GeoLocationResponseProps;

    if (!location.location) {
        return Promise.resolve();
    }

    let country = DEFAULT_COUNTRY.code;
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_FUNCTION_BASE_URL}/getCountry`,
            {
                ...location.location,
            },
            {
                headers: {
                    'Context-type': 'application/json',
                },
            }
        );

        if (response.status === 200) {
            country = response.data;
        }
    } catch (e) {
        console.error(e);
    }

    cookies.set(
        constants.COUNTRY_COOKIE_KEY,
        getCountryDetails(process.env.REACT_APP_TEST_COUNTRY || country),
        {
            maxAge: 7 * 24 * 60 * 60,
        }
    );

    if (process.env.REACT_APP_TEST_COUNTRY) {
        console.log('Country set to:', getUserCountry());
    }
};
