import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../../components/Firebase';
import Book from '../../../components/Book';
import { DigitalBookProps } from '../../../Types';
import { Container } from 'react-bootstrap';

export default () => {
    const dbDigitalBooks = useContext(FirebaseContext).dbDigitalBooks;
    const [allBooks, setAllBooks] = useState<DigitalBookProps[]>([]);
    useEffect(() => {
        if (!dbDigitalBooks) {
            return;
        }

        dbDigitalBooks
            .where('published', '==', 'true')
            .orderBy('rank')
            .get()
            .then((allDocs: any) => {
                const books: DigitalBookProps[] = [];
                allDocs.forEach((doc: any) => {
                    if (doc && doc.exists) {
                        books.push(doc.data());
                    }
                });
                setAllBooks(books);
            });
    }, [dbDigitalBooks]);

    return (
        <Container>
            <div style={{ padding: '0.5em' }}>
                {allBooks.map((book: DigitalBookProps, i: number) => {
                    return (
                        <div key={book.id + '-' + i}>
                            {i ? <br /> : null}
                            <Book {...book} />
                        </div>
                    );
                })}
            </div>
        </Container>
    );
};
