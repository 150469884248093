import { PriceByCountryProps } from '../../Types';
import toCurrency from './toCurrency';
import get from 'lodash/get';
import { DEFAULT_COUNTRY } from '../country';

export default (price: PriceByCountryProps, country: string): null | string => {
    if (!price[country]) {
        country = DEFAULT_COUNTRY.code;
    }

    const original = get(price, [country, 'original']);

    if (!original) {
        return null;
    }

    return toCurrency(price[country], 'original');
};
