import strings from '../../assets/text/strings';
import stringFn from '../../assets/text/stringFn';
import {
    DigitalBookProps,
    DownloadProgressProps,
    MiniTransactionStatusProps,
    TimedAlertProps,
    TransactionStatus,
} from '../../Types';

interface MyProps {
    product: undefined | DigitalBookProps;
    txnStatus: MiniTransactionStatusProps;
    authToken: string;
    setStatus: (status: TimedAlertProps) => void;
    setProcessingDownload: (status: boolean) => void;
    updateProgress: (progress: DownloadProgressProps) => void;
}

export default async ({
    product,
    txnStatus,
    authToken,
    setStatus,
    setProcessingDownload,
    updateProgress,
}: MyProps) => {
    if (!authToken) {
        return setStatus({
            title: strings.AuthenticationFailed,
            type: 'warning',
            message: strings.AccountWasChargedError + ' ' + stringFn.TxnId(txnStatus.txnId),
        });
    }

    if (txnStatus.status !== TransactionStatus.COMPLETED.toString()) {
        setStatus({
            title: strings.PaymentFailedTitle,
            type: 'warning',
            message: strings.PaymentFailedText + ' ' + stringFn.TxnId(txnStatus.txnId),
            showClose: true,
        });
        return;
    }

    setStatus({
        title: strings.OrderComplete,
        type: 'success',
        message: stringFn.FilesSentToEmail(txnStatus.emailDetails.join(', ')),
    });
    return;
};
