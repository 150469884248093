import { TextBuilderProps } from '../../Types';
import constants from '../constants';

export default {
    UnableToGetDownloadDetails: [
        {
            text: 'Unable to retrieve download details. Please try again later or ',
        },
        {
            text: 'email',
            url: `mailto:${constants.SUPPORT_EMAIL}`,
        },
        {
            text: ' us if the problem persists.',
        },
    ],
    FooterDisclaimer: [
        {
            text: `All contents provided by us are based on best of our knowledge. We do not take the responsibility of how the information provided by this website is used or the consequence of its use. Nor we take the responsibility of the accuracy of information provided by us. Few pictures have been taken from different sources, if any graphic or image is offensive or under any copyrights then please `,
        },
        {
            text: 'email us',
            url: `mailto:${constants.SUPPORT_EMAIL}`,
        },
        {
            text: ' at to get it removed. Please read full disclaimer',
        },
        {
            text: ' here.',
            url: `/disclaimer`,
        },
    ],
    DownloadRecommendation: [
        {
            text: 'You agree to our ',
        },
        {
            text: 'terms and conditions',
            url: '/terms-and-conditions',
        },
        {
            text: ' by clicking the download button.',
        },
    ],
} as {
    [key: string]: TextBuilderProps[];
};
