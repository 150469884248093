import { InlinePaymentProps } from '../../Types';
import { COUNTRY_INDIA } from '../../utils/country';
import indiaPaymentOptions from './indiaPaymentOptions';
import paypal from './paypal';

export default (props: InlinePaymentProps) => {
    if (props.country === COUNTRY_INDIA.code) {
        return indiaPaymentOptions(props);
    }

    // default payment
    return paypal(props);
};
