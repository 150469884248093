import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PolicyPage from '../../components/PolicyPage';
import policyText from './htmlData';

export default () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
            <PolicyPage
                title={'Terms and Conditions'}
                lastUpdated={'Nov 20, 2020'}
                policyText={policyText}
            />
        </Container>
    );
};
