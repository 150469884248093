import React from 'react';
import css from './index.module.scss';
import amazonIcon from '../../assets/images/amazonIcon.png';

interface MyProps {
    text: string;
    url: string;
}

export default ({ text, url }: MyProps) => {
    return (
        <div className={css.amazonButtonContainer}>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div className={css.amazonButton}>
                    <img src={amazonIcon} alt="amazon.com" className={css.amazonIcon}></img>
                    <div className={css.amazonText}>{text}</div>
                </div>
            </a>
        </div>
    );
};
