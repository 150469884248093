import { CountryDetailProps } from '../../Types';
import _cloneDeep from 'lodash/cloneDeep';

const COUNTRY_USA: CountryDetailProps = {
    code: 'us',
    currency_code: 'usd',
    name: 'United States',
};

const COUNTRY_INDIA: CountryDetailProps = {
    code: 'india',
    currency_code: 'inr',
    name: 'India',
};

const COUNTRY_SINGAPORE: CountryDetailProps = {
    code: 'singapore',
    currency_code: 'sgd',
    name: 'Singapore',
};

const COUNTRY_CANADA: CountryDetailProps = {
    code: 'canada',
    currency_code: 'cad',
    name: 'Canada',
};

const DEFAULT_COUNTRY: CountryDetailProps = _cloneDeep(COUNTRY_USA);

export default (country: undefined | string): CountryDetailProps => {
    console.log(country);
    switch (country?.toLowerCase()) {
        case 'india':
            return COUNTRY_INDIA;
        case 'singapore':
            return COUNTRY_SINGAPORE;
        case 'canada':
            return COUNTRY_CANADA;
        default:
            return DEFAULT_COUNTRY;
    }
};

export { DEFAULT_COUNTRY, COUNTRY_USA, COUNTRY_INDIA };
