import { CountryDetailProps } from '../../Types';
import { COUNTRY_INDIA } from '../country';

export default (country: CountryDetailProps) => {
    if (country.code.toLowerCase() === COUNTRY_INDIA.code.toLowerCase()) {
        return;
    }

    const url = `https://www.paypal.com/sdk/js?client-id=${
        process.env.REACT_APP_PAYPAL_CLIENT_ID
    }&currency=${country.currency_code.toUpperCase()}`;

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
};
