import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import css from './index.module.scss';

interface MyProps {
    images: string[];
    startAt?: number;
}

export default ({ images, startAt = 0 }: MyProps) => {
    const [currentIndex, setCurrentIndex] = useState<number>(startAt);
    return (
        <>
            <Row>
                <Col>
                    <Image src={images[currentIndex]} className={css.primary} fluid />
                </Col>
            </Row>
            <Row className="justify-content-center">
                {images.map((image, i) => {
                    return (
                        <Col key={`image-${i}`} className={css.thumbnail}>
                            <Image
                                onClick={() => {
                                    setCurrentIndex(i);
                                }}
                                src={image}
                                rounded
                                className={i === currentIndex ? css.isSelected : css.notSelected}
                            />
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};
