import { GiftDetailsProps } from '../../Types';

export default (
    giftingDetails: GiftDetailsProps | undefined,
    userEmail: undefined | null | string
): string => {
    if (!giftingDetails || !giftingDetails.isGift) {
        return '';
    }

    let returnStr = `Gift from ${giftingDetails?.fromName} (${userEmail}) to ${giftingDetails?.toName}`;
    if (
        giftingDetails?.optionalMessage &&
        returnStr.length + giftingDetails.optionalMessage.length <= 100
    ) {
        returnStr += `: ${giftingDetails.optionalMessage}`;
    }
    return returnStr;
};
