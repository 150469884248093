import React from 'react';
import PageTitle from '../PageTitle';

interface MyProps {
    title?: string;
    lastUpdated?: string;
    policyText: string;
}

export default ({ title, lastUpdated, policyText }: MyProps) => {
    return (
        <>
            <br />
            {title ? <PageTitle title={title} underline={false} /> : null}
            {lastUpdated ? <div className="text-muted">Last Updated: {lastUpdated}</div> : null}
            {title ? <hr /> : null}
            <div dangerouslySetInnerHTML={{ __html: policyText }} />
        </>
    );
};
