import firebase from 'firebase/app';

export interface FirebaseContextProps {
    auth: null | firebase.auth.Auth;
    dbDigitalBooks: any;
    dbSiteData: any;
    dbBatch: any;
}

export interface FirebaseUiConfigProps {
    signInFlow: string;
    signInSuccessUrl: string;
    signInOptions: string[];
}

export interface UserProfileProps {
    displayName: null | string;
    email: null | string;
    uid: null | string;
}
export interface SimpleError {
    type: string;
    title?: string;
    message: string;
}

export interface SimplePageStatus {
    type: string;
    title: string;
    body: string;
}

export interface UseDownloadFileProps {
    srcFilename: undefined | string;
    dstFilename: undefined | string;
    onLoaded?: () => void;
}

export interface DownloadProgressProps {
    inProgress: boolean;
    value: number;
}

export interface UserCreditsProps {
    credits: number;
    update_at: string;
}

export enum TransactionType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    NET_BANKING = 'NET BANKING',
}

export enum TransactionStatus {
    COMPLETED = 'COMPLETED',
    FAILURE = 'FAILURE',
    CHECKSUM_FAILED = 'CHECKSUM_FAILED',
    UNKNOWN = 'UNKNOWN',
}

export interface UserCreditLedger {
    userId: string;
    credits: number;
    type: TransactionType;
    reason: string;
    create_time: any;
    previousValue: number;
    adjustValue: number;
    order_ref?: string;
}
export interface PaypalItemProps {
    name: string;
    unit_amount: PriceProps;
    quantity: number;
    sku: string;
    category?: string;
}

export interface PaypalPaymentUnitsProps {
    amount: PriceProps;
    payee: {
        email_address: string;
        merchant_id: string;
    };
    items: PaypalItemProps[];
}

export interface PayerDetails {
    address: {
        country_code: string;
    };
    email_address: string;
    name: {
        given_name: string;
        surname: string;
    };
}

export interface PaypalPaymentDetailProps {
    create_time: string;
    id: string;
    intent: string;
    payer: PayerDetails;
    purchase_units: PaypalPaymentUnitsProps[];
    status: string;
    update_time: string;
}

export interface PaytmPaymentDetailProps {
    BANKNAME: string;
    BANKTXNID: string;
    CHECKSUMHASH: string;
    CURRENCY: string;
    GATEWAYNAME: string;
    MID: string;
    ORDERID: string;
    PAYMENTMODE: string;
    RESPCODE: string;
    RESPMSG: string;
    STATUS: string;
    TXNAMOUNT: string;
    TXNDATE: string;
    TXNID: string;
}

export interface OrderItemsProps {
    id: string;
    qty: number;
}

export interface PriceProps {
    value: number;
    currency_code: string;
    original?: number;
}

export interface PaperbackProps {
    vendor: string;
    url: string;
}

export interface PriceByCountryProps {
    [key: string]: PriceProps;
}

export interface PaperbackByCountryProps {
    [key: string]: PaperbackProps;
}

export interface PaymentDetailProps {
    id: string;
    userId: string;
    userEmail: string;
    create_time: string;
    intent: string;
    payer: PayerDetails;
    amount: PriceProps;
    status: string;
    items: OrderItemsProps[];
    paymentGateway: string;
    giftingDetails?: GiftDetailsProps;
}

export interface DigitalBookProps {
    id: string;
    title: string;
    subTitle?: string;
    description: string;
    credits: number;
    images: string[];
    ageGroup: string;
    price: PriceByCountryProps;
    pages: string;
    questions: number;
    features: string[];
    toc?: string[];
    rank: number;
    comingSoon?: string;
    paperback?: PaperbackByCountryProps;
}

export interface PaypalCheckoutButtonProp {
    currency?: string;
    total?: number;
    productId?: string;
    onSuccess: (paymentDetails: SavePaymentProps) => void;
    divRef: any;
    showPayment: boolean;
    getGiftingDetails: () => GiftDetailsProps | undefined;
}

export interface FaqProp {
    question: string;
    answer: string;
    rank?: number;
}

export interface TimedAlertProps {
    title?: string;
    message?: string;
    type: string;
    timeout?: number;
    showClose?: boolean;
}

export interface DownloadUrlProps {
    url: string;
    saveAs: string;
}

export interface TestimonialProps {
    rating: number;
    text: string;
    name: string;
    date: string;
    book?: string;
    rank?: number;
}

export interface TextBuilderProps {
    text: string;
    url?: string;
}

export interface BannerProps {
    url: string;
    rank: number;
    href?: string;
}

export interface GeoAddressResponseProps {
    address_components: any[];
    formatted_address: string;
    geometry: any;
    place_id: string;
    types: string[];
}

export interface GeoLocationResponseProps {
    location: {
        lat: number;
        lng: number;
    };
    accuracy: number;
}

export interface CountryDetailProps {
    code: string;
    currency_code: string;
    name: string;
}

export interface UrlLocationProps {
    hash: string;
    pathname: string;
    search: string;
    state: any;
}

export interface InlinePaymentProps {
    country: string;
    product: DigitalBookProps;
    onSuccess: (data: SavePaymentProps) => any;
    onProcessingChange: (status: boolean) => void;
    urlLocation: UrlLocationProps;
    getGiftingDetails: () => GiftDetailsProps | undefined;
}

export interface PaytmInitiateTxnProps {
    value: string;
    currency: string;
    items: any[];
}

export interface PaytmInitiateTransactionResponseProps {
    orderId: string;
    txnToken: string;
    amount: string;
}

export interface GiftDetailsProps {
    isGift: boolean;
    toName: string;
    fromName: string;
    toEmail: string;
    optionalMessage?: string;
}

export interface MiniTransactionStatusProps {
    status: string;
    txnId: string;
    emailDetails: string[];
}

export interface SavePaymentProps {
    orderId: string;
    paymentGateway: string;
    status: TransactionStatus;
    giftingDetails?: GiftDetailsProps;
}
