import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Firebase from '../components/Firebase';
import Footer from '../components/Footer';
import MenuBar from '../components/MenuBar';
import UserAuth from '../components/UserAuth';
import AboutUs from '../pages/AboutUs';
import BookDetails from '../pages/BookDetails';
import ContactUs from '../pages/ContactUs';
import CookiePolicies from '../pages/CookiePolicies';
import DeleteUserData from '../pages/DeleteUserData';
import Disclaimer from '../pages/Disclaimer';
import FAQS from '../pages/faqs';
import Home from '../pages/Home';
import PrivacyPolicies from '../pages/PrivacyPolicies';
import ReturnPolicies from '../pages/ReturnPolicies';
import SignIn from '../pages/SignIn';
import TermsAndConditions from '../pages/TermsAndConditions';
import './App.scss';

function App() {
    return (
        <Firebase>
            <Router>
                <UserAuth>
                    <MenuBar />
                    <Route path="/" exact render={() => <Home />} />
                    <Route path="/faqs" exact render={() => <FAQS />} />
                    <Route
                        path="/terms-and-conditions"
                        exact
                        render={() => <TermsAndConditions />}
                    />
                    <Route path="/privacy-policies" exact render={() => <PrivacyPolicies />} />
                    <Route path="/cookies-policies" exact render={() => <CookiePolicies />} />
                    <Route path="/delete-my-data" exact render={() => <DeleteUserData />} />
                    <Route path="/returns" exact render={() => <ReturnPolicies />} />
                    <Route path="/contact-us" exact render={() => <ContactUs />} />
                    <Route path="/disclaimer" exact render={() => <Disclaimer />} />
                    <Route path="/about-us" exact render={() => <AboutUs />} />
                    <Route path="/sign-in" exact render={() => <SignIn />} />
                    <Route path="/book/:id" exact render={(props) => <BookDetails {...props} />} />
                    <Footer />
                </UserAuth>
            </Router>
        </Firebase>
    );
}

export default App;
