import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import PageTitle from '../../components/PageTitle';
import css from './faqs.module.scss';
import Masonry from 'react-masonry-component';
import { sortBy } from 'lodash';
import { FaqProp } from '../../Types';
import useGetSiteData from '../../utils/hooks/useGetSiteData';

const masonryOptions = {
    transitionDuration: 0,
};

export default () => {
    const [allFaqs, setAllFaqs] = useState<FaqProp[]>();
    const [isLoading, siteData] = useGetSiteData('faqs');

    useEffect(() => {
        if (!isLoading || !siteData || !siteData.data) {
            return;
        }
        setAllFaqs(
            sortBy(siteData.data, (o) => {
                return o.rank;
            })
        );
    }, [isLoading, siteData]);

    return (
        <Container style={{ paddingTop: '1em' }}>
            <Row>
                <Col>
                    <PageTitle title={'Frequently asked questions (FAQs)'} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Masonry
                        className={''} // default ''
                        elementType={'div'} // default 'div'
                        options={masonryOptions}
                    >
                        {allFaqs?.map((faq, i) => {
                            return (
                                <Col key={'faq-' + i} lg={4} md={6} sm={12} className={css.faq}>
                                    <Card className={css.body}>
                                        <Card.Body>
                                            <Card.Title className={css.question}>{faq.question}</Card.Title>
                                            <Card.Text className={css.answer}>{faq.answer}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Masonry>
                </Col>
            </Row>
        </Container>
    );
};
