import React, { createContext, useContext, useEffect, useState } from 'react';
import _pick from 'lodash/pick';
import { UserProfileProps } from '../../Types';
import { FirebaseContext } from '../Firebase';
import { setUserCountry } from '../../utils/country';

export interface UserAuthContextProps {
    isLoading: boolean;
    user?: UserProfileProps;
    getAuthToken?: () => Promise<undefined | string>;
}

const UserAuthContext = createContext<UserAuthContextProps>({
    isLoading: true,
    user: undefined,
    getAuthToken: undefined,
});

export { UserAuthContext };

export default ({ children }: any) => {
    const firebase = useContext(FirebaseContext);
    const [currentUser, setCurrentUser] = useState<UserProfileProps>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    useEffect(() => {
        if (!firebase || !firebase.auth) {
            return;
        }

        return firebase.auth.onAuthStateChanged(async (user) => {
            if (user) {
                const tempUser: UserProfileProps = _pick(user, ['displayName', 'email', 'uid']);
                setCurrentUser(tempUser);
                setIsLoading(false);
            } else {
                setCurrentUser(undefined);
            }
        });
    }, [firebase]); // eslint-disable-line

    useEffect(() => {
        setUserCountry();
    }, []);

    const getAuthToken = async (): Promise<undefined | string> => {
        try {
            return await firebase.auth?.currentUser?.getIdToken(/* forceRefresh */ true);
        } catch (e) {
            console.error(e);
        }
        return Promise.resolve(undefined);
    };

    return (
        <UserAuthContext.Provider
            value={{
                user: currentUser,
                isLoading,
                getAuthToken,
            }}
        >
            {isLoading ? <h5>Loading...</h5> : children}
        </UserAuthContext.Provider>
    );
};
