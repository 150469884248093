import React from 'react';
import css from './index.module.scss';

interface MyProps {
    rating: number;
}

export default ({ rating }: MyProps) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
        stars.push(
            <span key={'star' + i} className={css.star}>
                ★
            </span>
        );
    }

    return <div className={css.rating + ' mb-2'}>{stars}</div>;
};
