import axios from 'axios';

export default async (dataObj: any, authToken: string): Promise<string> => {
    const response = await axios.post(
        `${process.env.REACT_APP_FUNCTION_BASE_URL}/getCcAvenueString`,
        dataObj,
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Context-type': 'application/json',
            },
        }
    );

    if (response.status !== 200) {
        console.error(response);
        throw new Error('Unable create Paytm order');
    }

    return response.data;
};
