import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import loadingImage from './loadingImage';
import getSampleUrl from '../../utils/firebase/getSampleUrl';
import css from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MyProps {
    productId: string;
    onClose: () => void;
}

export default ({ productId, onClose }: MyProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showNavButtons, setShowNavButtons] = useState<boolean>(false);
    const [fileForPreview, setFileForPreview] = useState<string>();

    const loadFilePreview = async (previewProductId: string) => {
        try {
            const fileUrlData = await getSampleUrl('guest', previewProductId, 'lookInside');
            if (!fileUrlData) {
                throw new Error('Cannot locate the file path');
            }
            setFileForPreview(fileUrlData.url);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    function onPreviewLoadSuccess(pdfProps: any) {
        setTotalNumberOfPages(pdfProps._pdfInfo.numPages);
        setPageNumber(1);
        setShowNavButtons(true);
    }

    function previewLoading() {
        return (
            <Row>
                <Col>
                    <Image src={loadingImage} fluid />
                </Col>
            </Row>
        );
    }

    const updatePageNumber = (incrementValue: number) => {
        if (incrementValue > 0) {
            if (pageNumber + incrementValue <= totalNumberOfPages) {
                setPageNumber(pageNumber + incrementValue);
            }
            return;
        }
        if (pageNumber + incrementValue > 0) {
            setPageNumber(pageNumber + incrementValue);
        }
    };

    const showPreviewButtons = () => {
        if (!showNavButtons) {
            return null;
        }

        return (
            <>
                <Row>
                    <Col xl={2} lg={2} md={2} sm={3} xs={3} style={{ padding: 0 }}>
                        <Button
                            size="sm"
                            variant={pageNumber > 1 ? 'secondary' : 'light'}
                            disabled={!(pageNumber > 1)}
                            onClick={() => {
                                updatePageNumber(-1);
                            }}
                        >
                            {'<'}
                        </Button>{' '}
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={6} xs={6} style={{ padding: 0, textAlign: 'center' }}>
                        <span className={css.pageNumber}>
                            Page {pageNumber} of {totalNumberOfPages}
                        </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={3} xs={3} style={{ padding: 0, textAlign: 'right' }}>
                        <Button
                            size="sm"
                            variant={pageNumber < totalNumberOfPages ? 'secondary' : 'light'}
                            disabled={!(pageNumber < totalNumberOfPages)}
                            onClick={() => {
                                updatePageNumber(1);
                            }}
                        >
                            {'>'}
                        </Button>
                    </Col>
                    <Button variant={'outline-secondary'} onClick={onClose} size="sm" className={css.closeButton}>
                        x
                    </Button>
                </Row>
            </>
        );
    };

    const showBookPreview = () => {
        if (isLoading) {
            return (
                <Container>
                    <Row>
                        <Col>Loading...</Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Container>
                <div className={css.bookPreview}>
                    {fileForPreview && (
                        <>
                            <Row>
                                <Col>
                                    <Document
                                        className={css.bookPreviewPdf}
                                        file={fileForPreview}
                                        loading={previewLoading}
                                        onLoadSuccess={onPreviewLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </Col>
                            </Row>
                            {showPreviewButtons()}
                        </>
                    )}
                </div>
            </Container>
        );
    };

    useEffect(() => {
        loadFilePreview(productId);
    }, [productId]);

    return <>{showBookPreview()}</>;
};
