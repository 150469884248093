import { PriceProps } from '../../Types';
import get from 'lodash/get';
import _round from 'lodash/round';

const adjustDecimal = (value: number, decimal: number): number => {
    if (_round(value, decimal) === value) {
        return decimal;
    }
    return 2;
};

export default (price: PriceProps, key: string = 'value', decimal: number = 2): string => {
    const finalDecimal = adjustDecimal(get(price, key), decimal);

    switch (price.currency_code.toLowerCase()) {
        case 'usd':
            return `$${get(price, key).toFixed(finalDecimal)}`;
        case 'sgd':
            return `S$${get(price, key).toFixed(finalDecimal)}`;
        case 'cad':
            return `$${get(price, key).toFixed(finalDecimal)}`;
        case 'inr':
            return `₹${get(price, key).toFixed(finalDecimal)}`;
        default:
            return `$${get(price, key).toFixed(finalDecimal)}`;
    }
};
