import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-component';
import { TestimonialProps } from '../../Types';
import useGetSiteData from '../../utils/hooks/useGetSiteData';
import Stars from '../Stars';
import css from './index.module.scss';

const masonryOptions = {
    transitionDuration: 0,
};

export default () => {
    const [testimonials, setTestimonials] = useState<TestimonialProps[]>();
    const [isLoading, siteData] = useGetSiteData('testimonials');

    useEffect(() => {
        if (!isLoading || !siteData || !siteData.data) {
            return;
        }
        setTestimonials(
            sortBy(siteData.data, (o) => {
                return o.rank;
            })
        );
    }, [isLoading, siteData]);

    return (
        <Container className={css.padding}>
            <div className={css.parent}>
                <Row className={css.sectionTitle + ' mb-2'}>
                    <Col>
                        <span>What people are saying...</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Masonry
                            className={''} // default ''
                            elementType={'div'} // default 'div'
                            options={masonryOptions}
                        >
                            {testimonials?.map(({ rating, text, name, date }, i) => {
                                return (
                                    <Col className={css.testimonialPadding} key={date + '-' + name + '-' + i} lg={4} xl={4} md={4} sm={12}>
                                        <Card className={css.body}>
                                            <Card.Body className={css.body}>
                                                <Stars rating={rating} />
                                                <div className={css.text}>{text}</div>
                                                <div className={css.byNameDate}>
                                                    by {name} on {date}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Masonry>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
