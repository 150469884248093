import constants from '../constants';

export default {
    PaymentDisclaimer:
        "We don't save any card/payment details. All transactions are executed securely via service providers such as Paypal, PayTm, and CCAvenue. Your privacy and security is our top priority.",
    SignInOptions:
        'We offer following sign in options at this time. Only your email address and profile name will be used for managing your orders.',
    NeverShareInformation:
        'Your privacy and security is our top priority. We will never share your details with anyone.',
    SignInHeader: 'Sign in or Create Account',
    SomethingWentWrong: 'Something went wrong on our end',
    BookNotFoundTitle: 'Book not found',
    BookNotFoundText:
        'We cannot find the book you are looking for. Please try another book. Sorry about that!',
    Downloading: 'Downloading',
    DownloadingPleaseWait:
        'Please wait while we process your order. Do not press the back button or refresh the page.',
    UnableToGetDownloadDetails:
        'Unable to retrieve download details. Please try again later or email us if the problem persists.',
    ThankYou: 'Thank you',
    SampleSuccessTitle: 'Thank you',
    SampleSuccessText: 'We hope your child enjoys the sample book.',
    AuthenticationFailed: 'Authentication failed',
    AccountWasChargedError: `If your account was charged, please contact us at ${constants.SUPPORT_EMAIL} with your transaction ID.`,
    PaymentFailedTitle: 'Payment failed',
    PaymentFailedText: `Payment was either denied or cancelled. If your account was still charged, please contact us at ${constants.SUPPORT_EMAIL} with your transaction ID.`,
    PaymentSuccessfulTitle: 'Payment successful',
    PleaseSignInToCompleteThePurchase: 'Please Sign-In to Continue',
    Free: 'Free',
    FreeDownload: 'Download for Free',
    OrderComplete: 'Order Complete!',
} as {
    [key: string]: string;
};
