import axios from 'axios';
import { PaytmInitiateTransactionResponseProps, PaytmInitiateTxnProps } from '../../Types';

export default async (
    txnAmount: PaytmInitiateTxnProps,
    authToken: string
): Promise<PaytmInitiateTransactionResponseProps> => {
    const response = await axios.post(
        `${process.env.REACT_APP_FUNCTION_BASE_URL}/paytmInitiateTransaction`,
        txnAmount,
        {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    if (response.status !== 200) {
        console.error(response);
        throw new Error('Unable create Paytm order');
    }
    return response.data;
};
