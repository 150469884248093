import React from 'react';
import { Link } from 'react-router-dom';
import { TextBuilderProps } from '../../Types';

interface MyProps {
    text: TextBuilderProps[];
    textClass?: string;
    linkClass?: string;
}

const isInternalLink = (url: string): boolean => {
    return url.startsWith('/') || url.startsWith('./');
};

export default ({ text, linkClass, textClass }: MyProps) => {
    const returnText = text.map((t, i) => {
        if (t.url) {
            if (isInternalLink(t.url)) {
                return (
                    <Link key={i} to={t.url} className={linkClass}>
                        {t.text}
                    </Link>
                );
            }

            return (
                <a href={t.url} key={i} className={linkClass}>
                    {t.text}
                </a>
            );
        }
        return (
            <span key={i} className={textClass}>
                {t.text}
            </span>
        );
    });
    return <>{returnText}</>;
};
