import { useContext, useEffect, useState } from 'react';
import { PaypalCheckoutButtonProp, PaypalItemProps, PaypalPaymentDetailProps } from '../../Types';
import { UserAuthContext } from '../../components/UserAuth';
import paypalTransactionStatus from '../paypal/paypalTransactionStatus';

export default ({
    currency,
    total,
    productId,
    onSuccess,
    divRef: paypalRef,
    showPayment,
}: PaypalCheckoutButtonProp) => {
    const { user: currentUser } = useContext(UserAuthContext);
    const [processingPayment, setProcessingPayment] = useState<boolean>(false);

    useEffect(() => {
        if (!productId) return;
        if (!(window as any).paypal) return;
        if (!currentUser) return;
        if (!paypalRef.current) return;

        paypalRef.current.innerHTML = '';
        if (!showPayment) {
            return;
        }

        (window as any).paypal
            .Buttons({
                createOrder: (data: any, actions: any) => {
                    const orderItems: PaypalItemProps[] = [
                        {
                            name: productId,
                            unit_amount: {
                                value: total as number,
                                currency_code: currency || 'USD',
                            },
                            quantity: 1,
                            sku: productId,
                            category: 'DIGITAL_GOODS',
                        },
                    ];

                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: total,
                                    currency_code: currency || 'USD',
                                    userId: currentUser.uid,
                                    breakdown: {
                                        item_total: {
                                            value: total,
                                            currency_code: currency || 'USD',
                                        },
                                    },
                                },
                                items: orderItems,
                            },
                        ],
                        application_context: {
                            shipping_preference: 'NO_SHIPPING',
                        },
                    });
                },
                onApprove: (data: any, actions: any) => {
                    setProcessingPayment(true);
                    return actions.order
                        .capture()
                        .then(async (resData: PaypalPaymentDetailProps) => {
                            await onSuccess({
                                orderId: resData.id,
                                paymentGateway: 'paypal',
                                status: paypalTransactionStatus(resData.status),
                            });
                            setProcessingPayment(false);
                        })
                        .catch((error: any) => {
                            console.error(error);
                        });
                },
            })
            .render(paypalRef.current);
    }, [productId, total, showPayment]); // eslint-disable-line
    return [processingPayment] as const;
};
