import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import firebaseConfig from '../../assets/Firebase/config';
import { FirebaseContextProps } from '../../Types';

const FirebaseContext = createContext<FirebaseContextProps>({
    auth: null,
    dbDigitalBooks: null,
    dbSiteData: null,
    dbBatch: null,
});

export { FirebaseContext };

export default ({ children }: any) => {
    const [firebaseContext, setFirebaseContext] = useState<FirebaseContextProps>({
        auth: null,
        dbDigitalBooks: null,
        dbSiteData: null,
        dbBatch: null,
    });

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        const db = firebase.firestore();
        setFirebaseContext({
            auth: firebase.auth(),
            dbDigitalBooks: db.collection('digital-books'),
            dbSiteData: db.collection('site-data'),
            dbBatch: () => db.batch(),
        });
    }, []);

    return <FirebaseContext.Provider value={firebaseContext}>{children}</FirebaseContext.Provider>;
};
