import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { BannerProps } from '../../Types';
import useGetSiteData from '../../utils/hooks/useGetSiteData';
import sortBy from 'lodash/sortBy';
import css from './index.module.scss';

export default () => {
    const [banners, setBanners] = useState<BannerProps[]>();
    const [isLoading, siteData] = useGetSiteData('banners');

    useEffect(() => {
        if (!isLoading || !siteData || !siteData.data) {
            return;
        }

        setBanners(
            sortBy(siteData.data, (o) => {
                return o.rank;
            })
        );
    }, [isLoading, siteData]);

    return (
        <>
            <Carousel indicators={false}>
                {banners &&
                    banners.map((banner, i) => {
                        return (
                            <Carousel.Item key={'banner' + i}>
                                {banner.href ? (
                                    <a href={banner.href} target="_blank" rel="noopener noreferrer">
                                        <img
                                            className="d-block w-100"
                                            src={banner.url}
                                            alt="Bright Beginning"
                                        />
                                    </a>
                                ) : (
                                    <img
                                        className="d-block w-100"
                                        src={banner.url}
                                        alt="Bright Beginning"
                                    />
                                )}
                            </Carousel.Item>
                        );
                    })}
            </Carousel>
            <div className={css.bannerDivider} />
        </>
    );
};
