import { PriceByCountryProps } from '../../Types';
import toCurrency from './toCurrency';
import { DEFAULT_COUNTRY } from '../country';

export default (price: PriceByCountryProps, countryCode: string, decimal?: number): string => {
    if (!price[countryCode]) {
        countryCode = DEFAULT_COUNTRY.code;
    }
    return toCurrency(price[countryCode], 'value', decimal);
};
