import React from 'react';
import Banner from '../../components/Banner';
import TestimonialSummary from '../../components/TestimonialSummary';
import Products from './Products';

export default () => {
    return (
        <>
            <Banner />
            <Products />
            <TestimonialSummary />
        </>
    );
};
