import { TransactionStatus } from '../../Types';

export default (status: string): TransactionStatus => {
    switch (status) {
        case 'TXN_SUCCESS':
        case 'txn_success':
            return TransactionStatus.COMPLETED;
        case 'TXN_FAILURE':
        case 'txn_failure':
            return TransactionStatus.FAILURE;
    }
    return TransactionStatus.UNKNOWN;
};
