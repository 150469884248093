import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import printIcon from '../../assets/images/printer.png';
import practiceIcon from '../../assets/images/practice.png';
import repeatIcon from '../../assets/images/repeat.png';
import css from './index.module.scss';

const PprData = [
    {
        title: 'Print',
        text: 'as needed',
        icon: printIcon,
    },
    {
        title: 'Practice',
        text: 'to learn and improve',
        icon: practiceIcon,
    },
    {
        title: 'Repeat',
        text: 'as many times',
        icon: repeatIcon,
    },
] as {
    title: string;
    text: string;
    icon: string;
}[];

export default () => {
    return (
        <div className={css.pprModel}>
            <Row className={css.sectionTitle + ' mb-4'}>
                <Col>
                    <span>How it works...</span>
                </Col>
            </Row>
            <Row>
                {PprData.map(({ title, text, icon }, i) => {
                    return (
                        <Col lg={4} xl={4} md={4} sm={12} key={title + '-' + i} className={css.model}>
                            <Image src={icon} className={css.icon} />
                            <div className={css.title}>{title}</div>
                            <div className={css.text}>{text}</div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};
