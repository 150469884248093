import singularPlural from '../../utils/strings/singularPlural';
import constants from '../constants';

export default {
    TxnId: (id: string) => {
        return `Transaction ID: ${id}`;
    },
    DiscountString: (discount: number) => {
        return `${discount}% off - limited time deal`;
    },
    AppreciateYourBusiness: (fileCount: number) => {
        return `Your book (${singularPlural(
            'file',
            fileCount,
            true
        )}) has been downloaded successfully. Please check your download's folder. We appreciate your business.`;
    },
    FilesSentToEmail: (email: string) => {
        return `We are preparing your workbook(s) to be sent to ${email}. Please check the spam or contact us at ${constants.SUPPORT_EMAIL}, incase the email is not delivered. Thank you!`;
    },
} as {
    [key: string]: (params: any) => string;
};
