import axios from 'axios';
import { DownloadUrlProps } from '../../Types';

const GET_PATH = process.env.REACT_APP_FUNCTION_BASE_URL + '/getSampleUrl?id=';
export default async (authToken: string, productId: string, type: string): Promise<DownloadUrlProps> => {
    const fileUrl: {
        status: number;
        data: DownloadUrlProps;
    } = await axios.get(GET_PATH + productId + `&type=${type}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });

    if (fileUrl.status !== 200) {
        console.log(fileUrl);
        throw new Error('Unable get file details');
    }
    return fileUrl.data;
};
