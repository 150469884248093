import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, ProgressBar, Row } from 'react-bootstrap';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebaseUiConfig from '../../assets/Firebase/uiConfig';
import strings from '../../assets/text/strings';
import BookPreview from '../../components/BookPreview';
import ShowFeatures from '../../components/BookSummary/ShowFeatures';
import { FirebaseContext } from '../../components/Firebase';
import InlinePaymentOptions from '../../components/InlinePaymentOptions';
import PageTitle from '../../components/PageTitle';
import ProductImages from '../../components/ProductImages';
import TimedAlert from '../../components/TimedAlert';
import { UserAuthContext } from '../../components/UserAuth';
import {
    CountryDetailProps,
    DownloadProgressProps,
    GiftDetailsProps,
    MiniTransactionStatusProps,
    SavePaymentProps,
    TimedAlertProps,
} from '../../Types';
import parseDiscount from '../../utils/currency/parseDiscount';
import parseDiscountString from '../../utils/currency/parseDiscountString';
import parsePrice from '../../utils/currency/parsePrice';
import useFindBookById from '../../utils/hooks/useFindBookById';
import singularPlural from '../../utils/strings/singularPlural';
import downloadFreeSample from './downloadFreeSample';
import downloadFullBook from './downloadFullBook';
import css from './index.module.scss';
import PaymentDisclaimer from './PaymentDisclaimer';
import { COUNTRY_INDIA, DEFAULT_COUNTRY, getUserCountry } from '../../utils/country';
import isFree from '../../utils/currency/isFree';
import { Redirect } from 'react-router-dom';
import loadPaypalScript from '../../utils/paypal/loadPaypalScript';
import base64ToString from '../../utils/strings/base64ToString';
import getGiftHeaderString from '../../utils/strings/getGiftHeaderString';
import { savePaymentTxn } from '../../utils/payment';
import BuyOnAmazonButton from '../../components/BuyOnAmazonButton';

const showCredits = process.env.REACT_APP_USE_CREDITS === 'true';
const showPrice = process.env.REACT_APP_USE_PRICE === 'true';
const BUTTON_SIZE = 'lg';

export default (props: any) => {
    const firebase = useContext(FirebaseContext);
    const { user: currentUser, getAuthToken } = useContext(UserAuthContext);
    const productId = _get(props, ['match', 'params', 'id']);
    const queryParams = qs.parse(_get(props, ['location', 'search'], ''), {
        ignoreQueryPrefix: true,
    });
    if (!productId) {
        return <></>;
    }

    const [userCountry, setUserCountry] = useState<CountryDetailProps>(DEFAULT_COUNTRY);
    const [paymentReady, setPaymentReady] = useState<boolean>(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        const _userCountry = getUserCountry();
        setUserCountry(_userCountry);
        loadPaypalScript(_userCountry);
        setTimeout(() => {
            setPaymentReady(true);
        }, 1000);
    }, []);

    const [sampleDownloaded, setSampleDownloaded] = useState<boolean>(false);
    const [processingDownload, setProcessingDownload] = useState<boolean>(false);
    const [downloadProgress, setDownloadProgress] = useState<DownloadProgressProps>();
    const statusRef = useRef(null);
    const [currentStatus, setCurrentStatus] = useState<TimedAlertProps>();
    const [isLoading, product] = useFindBookById(productId);
    const [showPreviewButton, setShowPreviewButton] = useState<boolean>(
        queryParams['look-inside'] ? false : true
    );
    const [showProductImages, setShowProductImages] = useState<boolean>(
        queryParams['look-inside'] ? false : true
    );
    const [showDefaultButtonGroup, setShowDefaultButtonGroup] = useState<boolean>(
        queryParams.download ? false : true
    );
    const [showPaymentOptions, setShowPaymentOptions] = useState<boolean>(
        queryParams.download ? true : false
    );
    const [hidePaymentOptions, setHidePaymentOptions] = useState<boolean>(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);
    const [redirectToNewPage, setRedirectToNewPage] = useState<string>();

    const getFreeSample = async () => {
        const authToken = (getAuthToken ? await getAuthToken() : undefined) || 'guest';
        await downloadFreeSample({
            authToken,
            product,
            setStatus: setCurrentStatus,
            setProcessingDownload,
            updateProgress: setDownloadProgress,
        });
        setSampleDownloaded(true);
    };

    const showLoading = () => {
        if (!isLoading) {
            return null;
        }
        return <h5>Loading...</h5>;
    };

    const loadPreview = () => {
        setShowPreviewButton(false);
        setShowProductImages(false);
    };

    const showInvalidProductError = () => {
        if (isLoading || product) {
            return null;
        }

        return (
            <div>
                <h5>Something went wrong</h5>
                <div>
                    <span className={css.invalidProduct}>
                        Looks like the product you are looking for is invalid or has been removed.
                        Please try another product.
                    </span>
                </div>
            </div>
        );
    };

    const downloadWithPaymentClicked = () => {
        setHidePaymentOptions(false);
        setShowPaymentOptions(true);
        setShowDefaultButtonGroup(false);
    };

    const paymentCancelClick = () => {
        setHidePaymentOptions(true);
        setShowPaymentOptions(false);
        setShowDefaultButtonGroup(true);
    };

    const startFullBookDownload = async (txnStatus: MiniTransactionStatusProps) => {
        if (statusRef && statusRef.current) {
            (statusRef?.current as any).scrollIntoView();
        }

        const authToken = (getAuthToken ? await getAuthToken() : undefined) || 'guest';
        await downloadFullBook({
            authToken,
            product,
            txnStatus,
            setStatus: (status) => {
                if (status.type === 'danger') {
                    paymentCancelClick();
                    setProcessingDownload(false);
                } else {
                    setShowPaymentOptions(false);
                }
                setCurrentStatus(status);
            },
            setProcessingDownload,
            updateProgress: setDownloadProgress,
        });

        // without using timeout, the react component un-mounting is not clean
        setTimeout(() => {
            setRedirectToNewPage(_get(props, ['location', 'pathname'], '/'));
        }, 100);
    };

    const savePayment = async (paymentDetails: SavePaymentProps) => {
        if (!getAuthToken) {
            console.error();
            return;
        }

        const authToken = await getAuthToken();
        if (!authToken) {
            console.error();
            return;
        }

        paymentDetails.giftingDetails = getGiftingDetails();
        await savePaymentTxn(authToken, paymentDetails);
        startFullBookDownload({
            txnId: paymentDetails.orderId,
            status: paymentDetails.status,
            emailDetails: [
                paymentDetails.giftingDetails?.isGift
                    ? paymentDetails.giftingDetails?.toEmail
                    : (currentUser?.email as string),
            ],
        });
    };

    const processingStateChange = (status: boolean) => {
        setIsProcessingPayment(status);
        if (status) {
            setHidePaymentOptions(status);
            setCurrentStatus({
                title: `Processing payment`,
                type: 'warning',
                message: `Please don't close or press the back button!`,
            });
        }
    };

    useEffect(() => {
        if (_isEmpty(queryParams.status)) {
            return;
        }
        const txnStatus = base64ToString<MiniTransactionStatusProps>(
            queryParams.status as string,
            true
        );
        if (!txnStatus || _isEmpty(txnStatus)) {
            return;
        }
        setProcessingDownload(true);
        processingStateChange(true);

        setTimeout(
            (status) => {
                startFullBookDownload(status);
            },
            1000,
            txnStatus
        );
    }, [queryParams.txnStatus, queryParams.txn]); // eslint-disable-line

    const getGiftingDetails = (): GiftDetailsProps | undefined => {
        // if (!validateGiftForm()) {
        //     return undefined;
        // }

        return {
            isGift: showGiftForm,
            toName: _get(giftFormRef, ['current', 'toName', 'value'], '').replace(/[^ -~]/g, ''),
            toEmail: _get(giftFormRef, ['current', 'toEmail', 'value'], ''),
            fromName: _get(giftFormRef, ['current', 'fromName', 'value'], '').replace(
                /[^ -~]/g,
                ''
            ),
            optionalMessage: _get(giftFormRef, ['current', 'optionalMessage', 'value'], '').replace(
                /[^ -~]/g,
                ''
            ),
        };
    };

    const showLoginComponent = () => {
        if (!firebase || !firebase.auth) {
            return <></>;
        }

        return (
            <Row style={{ padding: '0.5em' }}>
                <Col className={css.inlinePayment}>
                    <div className={css.text}>{strings.PleaseSignInToCompleteThePurchase}</div>
                    <FirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth} />
                    <Button
                        variant={'outline-secondary'}
                        onClick={paymentCancelClick}
                        size="sm"
                        className={css.closePaymentButton}
                    >
                        x
                    </Button>
                </Col>
            </Row>
        );
    };

    const renderPaymentOptions = () => {
        if (!paymentReady || !showPaymentOptions || !product || product.comingSoon) {
            return null;
        }

        if (!currentUser) {
            return showLoginComponent();
        }

        const paymentStyle = {
            display: hidePaymentOptions ? 'none' : 'block',
            padding: '0.5em',
        };

        return (
            <Row style={paymentStyle}>
                <Col className={css.inlinePayment}>
                    <InlinePaymentOptions
                        product={product}
                        onSuccess={savePayment}
                        onProcessingChange={processingStateChange}
                        country={userCountry.code}
                        getGiftingDetails={getGiftingDetails}
                        urlLocation={_get(props, 'location')}
                    />
                    <PaymentDisclaimer />
                    {!isProcessingPayment && (
                        <Button
                            variant={'outline-secondary'}
                            onClick={paymentCancelClick}
                            size="sm"
                            className={css.closePaymentButton}
                        >
                            x
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };

    const giftFormRef = useRef(null);
    const [showGiftForm, setShowGiftForm] = useState<boolean>(!!queryParams.gift);
    const [giftFormValid, setGiftFormValid] = useState(!!!queryParams.gift);
    const [giftHeader, setGiftHeader] = useState<string>('');
    const toggleGiftForm = (event: any) => {
        setShowGiftForm(event.target.checked);
        setGiftFormValid(!event.target.checked);
        paymentCancelClick();
        _set(giftFormRef, ['current', 'toName', 'value'], '');
        _set(giftFormRef, ['current', 'toEmail', 'value'], '');
        _set(giftFormRef, ['current', 'fromName', 'value'], '');
        _set(giftFormRef, ['current', 'optionalMessage', 'value'], '');
    };

    const validateGiftForm = () => {
        const formEvent: any = _get(giftFormRef, ['current']);
        const formValid = !showGiftForm || formEvent?.checkValidity();
        setGiftFormValid(formValid);
        setGiftHeader(getGiftHeaderString(getGiftingDetails(), currentUser?.email));
        return formValid;
    };

    const showPaperbackOption = () => {
        const paperback = product?.paperback;
        if (!paperback || !paperback[userCountry.code]) {
            return null;
        }

        return (
            <div>
                <Row>
                    <Col>
                        <BuyOnAmazonButton
                            text={'Buy Printed Book on Amazon'}
                            url={paperback[userCountry.code].url}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    const showGiftingOption = () => {
        if (!currentUser) {
            return <></>;
        }

        const style = {
            display: showGiftForm ? 'block' : 'none',
            padding: '0.5em',
        };

        return (
            <div style={style}>
                <Form
                    ref={giftFormRef}
                    noValidate
                    validated={showGiftForm}
                    onChange={validateGiftForm}
                >
                    <Form.Group controlId="toName">
                        <Form.Control
                            type="text"
                            name="toName"
                            disabled={isProcessingPayment || processingDownload}
                            placeholder="Recipient Name (Max 20)"
                            minLength={3}
                            maxLength={20}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="toEmail">
                        <Form.Control
                            type="email"
                            name="toEmail"
                            disabled={isProcessingPayment || processingDownload}
                            placeholder="Recipient Email Address"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="fromName">
                        <Form.Control
                            type="text"
                            name="fromName"
                            disabled={isProcessingPayment || processingDownload}
                            placeholder="From Name (Max 20)"
                            minLength={3}
                            maxLength={20}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="optionalMessage">
                        <Form.Control
                            type="text"
                            name="optionalMessage"
                            disabled={isProcessingPayment || processingDownload}
                            maxLength={50}
                            placeholder="Optional Print Message"
                        />
                        <Form.Text className="text-muted">
                            No special chars or emojis. Max 50 characters.
                        </Form.Text>
                    </Form.Group>
                    <Form.Control as="textarea" rows={4} readOnly placeholder={giftHeader} />
                    <Form.Text className="text-muted">
                        This will be printed in the top-left header of each page
                    </Form.Text>
                </Form>
            </div>
        );
    };

    const showDownloadButton = () => {
        if (!showDefaultButtonGroup || !product || product.comingSoon) {
            return null;
        }

        if (!currentUser) {
            return showLoginComponent();
        }

        const style = {
            display: processingDownload ? 'none' : 'block',
            padding: 0,
        };

        // control reaches here only when payment div must be rendered
        return (
            <div style={style}>
                {
                    <div style={{ display: 'flex' }}>
                        <Form.Group controlId="showGiftForm">
                            <Form.Check
                                className={css.isGiftCheckbox}
                                type="checkbox"
                                label="Send as Gift"
                                checked={showGiftForm}
                                disabled={isProcessingPayment || processingDownload}
                                onChange={toggleGiftForm}
                            />
                        </Form.Group>
                    </div>
                }
                {!sampleDownloaded && !showGiftForm && (
                    <Button
                        variant={isFree(product.price, userCountry?.code) ? 'success' : 'info'}
                        size={BUTTON_SIZE}
                        onClick={getFreeSample}
                        className={css.xsMargin}
                    >
                        {isFree(product.price, userCountry?.code) ? (
                            <span>Download for Free</span>
                        ) : (
                            <span>Free Sample</span>
                        )}
                    </Button>
                )}{' '}
                {showCredits && (
                    <Button variant="success" size={BUTTON_SIZE} className={css.xsMargin}>
                        Download for {singularPlural('credit', product.credits, true)}
                    </Button>
                )}{' '}
                {!isFree(product.price, userCountry?.code) && showPrice && !showGiftForm && (
                    <Button
                        variant="success"
                        size={BUTTON_SIZE}
                        disabled={!giftFormValid}
                        onClick={downloadWithPaymentClicked}
                        className={css.xsMargin}
                    >
                        Download for {parsePrice(product.price, userCountry?.code)}
                    </Button>
                )}{' '}
                {!isFree(product.price, userCountry?.code) && showPrice && showGiftForm && (
                    <Button
                        style={{ backgroundColor: '#8565c4' }}
                        variant="success"
                        size={BUTTON_SIZE}
                        disabled={!giftFormValid}
                        onClick={downloadWithPaymentClicked}
                        className={css.xsMargin}
                    >
                        Gift for {parsePrice(product.price, userCountry?.code)}
                    </Button>
                )}
                <br />
            </div>
        );
    };

    const showCurrentStatus = () => {
        if (!currentStatus) {
            return null;
        }
        return (
            <div>
                <TimedAlert {...currentStatus} />
            </div>
        );
    };

    const showDownloadProgress = () => {
        if (!downloadProgress?.inProgress) {
            return null;
        }

        return (
            <div className={css.progressBar}>
                <ProgressBar now={downloadProgress?.value} />
            </div>
        );
    };

    const previewClosed = () => {
        setShowProductImages(true);
        setShowPreviewButton(true);
    };

    const showComingSoon = (str: undefined | string) => {
        if (!str) {
            return null;
        }

        return <div className={css.comingSoon}>Coming soon!</div>;
    };

    const showPayTmBarcode = () => {
        if (true || userCountry.code !== COUNTRY_INDIA.code) {
            return null;
        }

        if (showGiftForm || isProcessingPayment || !product || product?.comingSoon) {
            return null;
        }

        return (
            <div style={{ padding: '0.5em' }}>
                <Row className={css.paytmQR}>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <img
                            style={{ maxWidth: '100%' }}
                            src="https://firebasestorage.googleapis.com/v0/b/bbonline-49229.appspot.com/o/public%2FpayTmBarcode.jpg?alt=media"
                            alt="payTmBarcode"
                        />
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <div style={{ fontSize: '1.2em' }}>
                            You can also&nbsp;
                            <strong style={{ color: 'navy' }}>Pay</strong>
                            <strong style={{ color: '#00BFF2' }}>TM</strong>
                            &nbsp;for fast checkout. We will contact you and email the files within
                            12 hours.
                            <br />
                            PayTM: <strong>+91-9971765577</strong> or &nbsp;<strong>Scan QR</strong>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <Container style={{ paddingTop: '1em' }}>
            <Row>
                <Col>
                    <PageTitle title={product?.title as string} />
                </Col>
            </Row>
            <Row>
                <Col xl={4} lg={4} md={5} sm={12}>
                    {showProductImages && (
                        <ProductImages images={product?.images || []} startAt={0} />
                    )}
                    {!showPreviewButton && (
                        <BookPreview productId={productId} onClose={previewClosed} />
                    )}
                    {showPreviewButton && (
                        <Button
                            variant={'secondary'}
                            size={BUTTON_SIZE}
                            block
                            onClick={loadPreview}
                            className={css.xsMargin}
                        >
                            Look Inside
                        </Button>
                    )}
                </Col>
                <Col xl={8} lg={8} md={7} sm={12}>
                    <br></br>
                    {showLoading()}
                    {showInvalidProductError()}

                    <div className={css.bookMain}>
                        {showComingSoon(product?.comingSoon)}
                        <div ref={statusRef} className={css.bookTitle}>
                            {product?.title}
                        </div>
                        <div className={css.bookSubtitle}>{product?.subTitle}</div>
                        <div>
                            <span className={css.textKey}>Recommended Age: </span>
                            <span className={css.textValue}>{product?.ageGroup}</span>
                        </div>
                        <div>
                            <span className={css.textKey}>Pages: </span>
                            <span className={css.textValue}>{product?.pages}</span>
                        </div>
                        {product?.price && !isFree(product?.price, userCountry?.code) ? (
                            <div style={{ marginBottom: '1em' }}>
                                <span className={css.textKey}>Price: </span>
                                <span className={css.priceValue}>
                                    {parsePrice(product?.price, userCountry?.code, 0)}
                                </span>{' '}
                                {parseDiscount(product.price, userCountry?.code) ? (
                                    <>
                                        <s className={css.originalPrice}>
                                            {parseDiscount(product.price, userCountry?.code)}
                                        </s>{' '}
                                        <span className={css.discountString}>
                                            ({parseDiscountString(product.price, userCountry?.code)}
                                            ){' '}
                                        </span>
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    {showGiftingOption()}
                    {showDownloadButton()}
                    {renderPaymentOptions()}
                    {showCurrentStatus()}
                    {showDownloadProgress()}
                    {showPayTmBarcode()}
                    {showPaperbackOption()}
                    {redirectToNewPage ? <Redirect to={redirectToNewPage} /> : null}
                </Col>
            </Row>
            <hr className="mb-2" />
            <Row>
                <Col>
                    <div className={css.segmentTitle}>Description</div>
                    <div className={css.segmentText}>{product?.description}</div>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <div className={css.segmentTitle}>Features</div>
                    <ShowFeatures features={product?.features} />
                </Col>
            </Row>
            <br />
            {product?.toc?.length && (
                <Row>
                    <Col>
                        <div className={css.segmentTitle}>Table of content</div>
                        <ul>
                            {product?.toc?.map((a, i) => {
                                return (
                                    <li className={css.tocText} key={a + '-' + i}>
                                        {a}
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                </Row>
            )}
        </Container>
    );
};
