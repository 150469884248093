import React from 'react';
import css from './pageTitle.module.scss';

interface MyProps {
    title: string;
    underline?: boolean;
}

export default (props: MyProps) => {
    if (props.underline === false) {
        return <div className={css.titleNoUnderline}>{props.title}</div>;
    }
    return <div className={css.title}>{props.title}</div>;
};
