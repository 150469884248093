import React from 'react';
import { Link } from 'react-router-dom';
import css from './siteMap.module.scss';

export default () => {
    return (
        <div className={css.parent}>
            <div className={css.menuItems}>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
                <Link to="/privacy-policies">Privacy Policies</Link>
                <Link to="/cookies-policies">Cookie Policies</Link>
                <Link to="/disclaimer">Disclaimer</Link>
                <Link to="/returns">Return Policy</Link>
                <Link to="/contact-us">Contact Us</Link>
            </div>
        </div>
    );
};
