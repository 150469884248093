import React from 'react';
import css from './showFeatures.module.scss';

interface MyProps {
    features: undefined | string[];
}

export default ({ features }: MyProps) => {
    if (!features || !features.length) {
        return null;
    }

    return (
        <div className={css.allFeatures}>
            {features.map((feature, i) => {
                return (
                    <div key={i + '-' + feature} className={css.feature}>
                        <span className={css.featureTick}>✓</span>
                        <span>{feature}</span>
                    </div>
                );
            })}
        </div>
    );
};
