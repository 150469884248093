import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { TimedAlertProps } from '../../Types';

export default ({ title, message, type, timeout, showClose }: TimedAlertProps) => {
    const [show, setShow] = useState<boolean>(true);
    const [countDown, setCountDown] = useState<number>(0);

    useEffect(() => {
        setShow(true);
    }, [message, title]);

    const hideAlert = () => {
        setShow(false);
    };

    const startCountDown = (value: number) => {
        value--;
        if (!value) {
            hideAlert();
            return;
        }
        setCountDown(value);
        setTimeout(startCountDown, 1000, value);
    };

    useEffect(() => {
        if (!timeout) {
            return;
        }
        startCountDown(timeout);
    }, [timeout]); // eslint-disable-line

    return (
        <>
            <Alert show={show} variant={type}>
                {title && <Alert.Heading>{title}</Alert.Heading>}
                {message && <p>{message}</p>}
                {showClose && (
                    <>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button onClick={hideAlert} variant={`outline-${type}`}>
                                Close {countDown ? `(${countDown})` : ''}
                            </Button>
                        </div>
                    </>
                )}
            </Alert>
        </>
    );
};
