import React, { useContext, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import { Link, useHistory } from 'react-router-dom';
import { UserAuthContext } from '../UserAuth';
import logo from '../../assets/images/logo_fff500.png';
import bbTextLine from '../../assets/images/bbTextLine.png';
import { FirebaseContext } from '../Firebase';
import './menubar.scss';

export default () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { user: currentUser } = useContext(UserAuthContext);
    const firebase = useContext(FirebaseContext);
    const history = useHistory();

    const signOut = () => {
        if (!firebase || !firebase.auth) {
            return;
        }
        firebase.auth.signOut().then(() => {
            history.push('/');
        });
    };

    const closeNavBar = () => {
        setTimeout(() => {
            setExpanded(false);
        }, 333);
    };

    return (
        <Navbar expand="lg" expanded={expanded} className={'menuBar'}>
            <Navbar.Brand>
                <Link to="/" className="siteTitle">
                    <div className={'logoParent'}>
                        <Image src={logo} alt="logo" className={'bbLogo'} />{' '}
                    </div>
                    <div className="brandName">
                        <div className={'bbText'}>Bright Beginning</div>
                        <Image src={bbTextLine} alt="Bright Beginning" className={'bbUnderline'} />
                    </div>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-right">
                    <Link to="/" onClick={closeNavBar} className={'menuItem'}>
                        Home
                    </Link>
                    <Link to="/faqs" onClick={closeNavBar} className={'menuItem'}>
                        FAQs
                    </Link>
                    <Link to="/about-us" onClick={closeNavBar} className={'menuItem'}>
                        About Us
                    </Link>
                    {currentUser ? (
                        <Link to="#" onClick={signOut} className={'menuItem'}>
                            Sign out
                        </Link>
                    ) : (
                        <Link to="/sign-in" onClick={closeNavBar} className={'menuItem'}>
                            Sign in
                        </Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
