import { PriceByCountryProps } from '../../Types';
import get from 'lodash/get';
import stringFn from '../../assets/text/stringFn';
import { DEFAULT_COUNTRY } from '../country';

export default (price: PriceByCountryProps, country: string): null | string => {
    if (!price[country]) {
        country = DEFAULT_COUNTRY.code;
    }

    const original = get(price, [country, 'original']);
    if (!original) {
        return null;
    }
    const actual = get(price, [country, 'value']);

    return stringFn.DiscountString((100 - (100 * actual) / original).toFixed(0));
};
