import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { InlinePaymentProps } from '../../Types';
import Paytm from './paytm';
import isEmpty from 'lodash/isEmpty';
import { getCcAvenueString } from '../../utils/ccAvenue';
import { UserAuthContext } from '../UserAuth';
import parsePrice from '../../utils/currency/parsePrice';
import getCurrency from '../../utils/currency/getCurrency';
import getPrice from '../../utils/currency/getPrice';
import { getCountryDetails } from '../../utils/country';
import jsonToBase64 from '../../utils/strings/jsonToBase64';
import css from './index.module.scss';

const AllowCcAvenue = true;
const AllowPaytm = true;

export default (props: InlinePaymentProps) => {
    const { getAuthToken } = useContext(UserAuthContext);
    const [paymentGateway, setPaymentGateway] = useState<string>();
    const { country, product, urlLocation, onProcessingChange, getGiftingDetails } = props;
    const usePaytm = () => {
        setPaymentGateway('paytm');
    };

    const showPaytm = () => {
        if (isEmpty(paymentGateway)) {
            return null;
        }

        if (AllowPaytm) {
            return <Paytm {...props} />;
        }

        return null;
    };

    const processPaymentWithCcAvenue = async (event: any) => {
        event.persist();
        event.preventDefault();
        const authToken = getAuthToken ? await getAuthToken() : undefined;
        if (!authToken) {
            return false;
        }

        onProcessingChange(true);
        const items = [
            {
                id: product.id,
                qty: 1,
            },
        ];

        const countryDetails = getCountryDetails(country);

        const samplePaymentObj = {
            currency: getCurrency(product.price, country).toUpperCase(),
            amount: getPrice(product.price, country).toString(),
            billing_country: countryDetails.name,
            merchant_param1: product.title,
            merchant_param2: jsonToBase64(items),
            merchant_param3: urlLocation.pathname,
            merchant_param5: getGiftingDetails() ? jsonToBase64(getGiftingDetails()) : '',
            redirect_url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/ccAvenueTransactionStatus`,
            cancel_url: `${process.env.REACT_APP_FUNCTION_BASE_URL}/ccAvenueTransactionStatus`,
        };
        const txnString = await getCcAvenueString(samplePaymentObj, authToken);
        event.nativeEvent.target[2].value = txnString;
        event.nativeEvent.target[3].value = process.env.REACT_APP_CCAVENUE_ACCESS_CODE;
        event.target.submit();
    };

    const loadCcAvenueForm = () => {
        if (!AllowCcAvenue) {
            return null;
        }

        return (
            <form
                id="ccForm"
                onSubmit={processPaymentWithCcAvenue}
                method={'post'}
                action={`${process.env.REACT_APP_CCAVENUE_HOST}/transaction/transaction.do?command=initiateTransaction`}
            >
                <input type="hidden" id="encRequest" name="encRequest" value="" />
                <input type="hidden" name="access_code" id="access_code" value="" />
            </form>
        );
    };

    return (
        <div>
            {isEmpty(paymentGateway) ? (
                <div>
                    <h5>Your account will be charged: {parsePrice(product.price, country)}</h5>
                    <h6>Please select your mode of payment:</h6>
                    {AllowCcAvenue ? (
                        <Button
                            variant={'success'}
                            size="lg"
                            form={'ccForm'}
                            type={'submit'}
                            className={css.buttonMargin}
                        >
                            Credit Cards
                        </Button>
                    ) : null}{' '}
                    {AllowCcAvenue ? (
                        <Button
                            variant={'success'}
                            size="lg"
                            form={'ccForm'}
                            type={'submit'}
                            className={css.buttonMargin}
                        >
                            Net Banking
                        </Button>
                    ) : null}{' '}
                    {AllowPaytm ? (
                        <Button
                            variant={'success'}
                            size="lg"
                            onClick={usePaytm}
                            className={css.buttonMargin}
                        >
                            PayTm
                        </Button>
                    ) : null}
                </div>
            ) : null}
            {showPaytm()}
            {loadCcAvenueForm()}
        </div>
    );
};
