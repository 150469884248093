import React, { useContext, useEffect, useState } from 'react';
import { InlinePaymentProps, PaytmPaymentDetailProps } from '../../Types';
import getCurrency from '../../utils/currency/getCurrency';
import getPrice from '../../utils/currency/getPrice';
import parsePrice from '../../utils/currency/parsePrice';
import initiateTransaction from '../../utils/paytm/initiateTransaction';
import paytmTransactionStatus from '../../utils/paytm/paytmTransactionStatus';
import { UserAuthContext } from '../UserAuth';
import css from './paytm.module.scss';

export default ({
    country,
    product,
    onSuccess,
    onProcessingChange,
    getGiftingDetails,
}: InlinePaymentProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { user: currentUser, getAuthToken } = useContext(UserAuthContext);

    const getTxnToken = async () => {
        if (!product) {
            console.error('Product not found!');
            return;
        }

        setIsLoading(true);
        const authToken = getAuthToken ? await getAuthToken() : undefined;
        if (!currentUser || !authToken) {
            console.error('Please sign in to continue!');
            return;
        }

        let paytmTxn;
        try {
            paytmTxn = await initiateTransaction(
                {
                    currency: getCurrency(product.price, country).toUpperCase(),
                    value: getPrice(product.price, country).toString(),
                    items: [product.id],
                },
                authToken
            );
        } catch (error) {
            setIsLoading(false);
            console.error('Something went wrong. We will show error');
            return;
        }

        if (!paytmTxn.txnToken) {
            setIsLoading(false);
            console.error('Something went wrong. We will show error');
            return;
        }

        const domWindow = window as any;
        const config = {
            root: '#payTmWindow',
            flow: 'DEFAULT',
            data: {
                orderId: paytmTxn.orderId,
                token: paytmTxn.txnToken,
                tokenType: 'TXN_TOKEN',
                amount: getPrice(product.price, country).toString(),
            },
            merchant: {
                name: process.env.REACT_APP_PAYTM_STORE_NAME,
                redirect: false,
            },
            handler: {
                notifyMerchant: (eventName: any, data: any) => {
                    // console.log('notifyMerchant handler function called');
                    // console.log('eventName => ', eventName);
                    // console.log('data => ', data);
                },
                transactionStatus: async (data: PaytmPaymentDetailProps) => {
                    onProcessingChange(true);
                    domWindow.Paytm.CheckoutJS.close();
                    await onSuccess({
                        orderId: data.ORDERID,
                        paymentGateway: 'paytm',
                        status: paytmTransactionStatus(data.STATUS),
                    });
                },
            },
        };

        if (domWindow.Paytm && domWindow.Paytm.CheckoutJS) {
            await domWindow.Paytm.CheckoutJS.init(config);
        }

        setIsLoading(false);
        return domWindow.Paytm.CheckoutJS.invoke();
    };

    useEffect(() => {
        getTxnToken();
    }, []); // eslint-disable-line

    return (
        <div id="payTmWindow">
            {isLoading ? <h4>Please wait... it could take upto 10 seconds</h4> : null}
            {!isLoading ? (
                <div className={css.body}>
                    <h6 className={css.proforma}>
                        Your account will be charged: {parsePrice(product.price, country)}
                    </h6>
                    <div id="paytm-checkoutjs" />
                </div>
            ) : null}
        </div>
    );
};
