import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../../components/Firebase';

export default (dataType: string) => {
    const { dbSiteData } = useContext(FirebaseContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>();

    const fetchSiteData = async (type: string) => {
        setIsLoading(true);
        try {
            const dataDoc = await dbSiteData.doc(type).get();
            if (dataDoc.exists) {
                setData(dataDoc.data());
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!dataType || !dbSiteData) {
            return;
        }
        fetchSiteData(dataType);
    }, [dataType]); // eslint-disable-line

    return [isLoading, data] as const;
};
