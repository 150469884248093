import { TransactionStatus } from '../../Types';

export default (status: string): TransactionStatus => {
    switch (status) {
        case 'COMPLETED':
        case 'completed':
            return TransactionStatus.COMPLETED;
    }
    return TransactionStatus.FAILURE;
};
