import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { DigitalBookProps, PaperbackProps } from '../../Types';
import singularPlural from '../../utils/strings/singularPlural';
import parsePrice from '../../utils/currency/parsePrice';
import css from './book.module.scss';
import constants from '../../assets/constants';
import parseDiscount from '../../utils/currency/parseDiscount';
import parseDiscountString from '../../utils/currency/parseDiscountString';
import { DEFAULT_COUNTRY, getUserCountry } from '../../utils/country';
import isFree from '../../utils/currency/isFree';
import BuyOnAmazonButton from '../BuyOnAmazonButton';

const showCredits = process.env.REACT_APP_USE_CREDITS === 'true';
const showPrice = process.env.REACT_APP_USE_PRICE === 'true';
const BUTTON_SIZE = 'sm';

export default ({
    id,
    title,
    subTitle,
    description,
    credits,
    ageGroup,
    images,
    price,
    pages,
    questions,
    features,
    comingSoon,
    paperback,
}: DigitalBookProps) => {
    const [userCountry, setUserCountry] = useState<string>(DEFAULT_COUNTRY.code);
    const [paperbackDetails, setPaperbackDetails] = useState<PaperbackProps>();
    useEffect(() => {
        window.scrollTo(0, 0);
        const country = getUserCountry();
        setUserCountry(country.code);
        if (paperback && paperback[country.code]) {
            setPaperbackDetails(paperback[country.code]);
        }
    }, [paperback]);

    const showFeatures = () => {
        if (!features || !features.length) {
            return;
        }

        return (
            <div className={css.allFeatures}>
                {features.map((feature, i) => {
                    if (i > constants.MAX_FEATURES) return null;
                    if (i === constants.MAX_FEATURES) {
                        return (
                            <div key={i + '-more'} className={css.feature}>
                                <span className={css.featureTick}>✓</span>
                                <Link to={comingSoon ? '#' : '/book/' + id}>
                                    <span className={css.featureTick}>...more</span>
                                </Link>
                            </div>
                        );
                    }
                    return (
                        <div key={i + '-' + feature} className={css.feature}>
                            <span className={css.featureTick}>✓</span>
                            <span>{feature}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const showComingSoon = (str: undefined | string) => {
        if (!str) {
            return null;
        }

        return <div className={css.comingSoon}>Coming soon!</div>;
    };

    return (
        <div className={css.book}>
            <Row style={{ textAlign: 'left' }}>
                <Col xl={3} lg={4} md={4} sm={12}>
                    <Link to={comingSoon ? '#' : '/book/' + id}>
                        <img src={images[0]} style={{ maxWidth: '100%' }} alt="" />
                    </Link>
                </Col>
                <Col xl={9} lg={8} md={8} sm={12}>
                    {showComingSoon(comingSoon)}
                    <div className={css.bookTitle}>
                        <span>{title}</span>
                    </div>
                    <div className={css.bookSubtitle}>{subTitle}</div>
                    {!comingSoon ? <div className={css.bookId}>Book ID: {id}</div> : null}
                    <div />
                    <div>
                        <span className={css.textKey}>Recommended Age: </span>
                        <span className={css.textValue}>{ageGroup}</span>
                    </div>
                    {!comingSoon ? (
                        <div>
                            <span className={css.textKey}>Pages: </span>
                            <span className={css.textValue}>{pages}</span>
                        </div>
                    ) : null}
                    <h1>{isFree(price, userCountry)}</h1>
                    {!isFree(price, userCountry) && !comingSoon ? (
                        <div>
                            <span className={css.textKey}>Price: </span>
                            <span className={css.priceValue}>
                                {parsePrice(price, userCountry, 0)}
                            </span>{' '}
                            {parseDiscount(price, userCountry) ? (
                                <>
                                    <s className={css.originalPrice}>
                                        {parseDiscount(price, userCountry)}
                                    </s>{' '}
                                    <span className={css.discountString}>
                                        ({parseDiscountString(price, userCountry)}){' '}
                                    </span>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                    {showFeatures()}
                    <div />
                    {!comingSoon ? (
                        <div className={css.buttonGroup}>
                            <Link to={'/book/' + id + '?look-inside=true'}>
                                <Button
                                    variant={'secondary'}
                                    size={BUTTON_SIZE}
                                    className={css.xsMargin}
                                >
                                    Look Inside
                                </Button>
                            </Link>{' '}
                            <Link to={'/book/' + id}>
                                <Button
                                    variant={isFree(price, userCountry) ? 'success' : 'info'}
                                    size={BUTTON_SIZE}
                                    className={css.xsMargin}
                                >
                                    {isFree(price, userCountry) ? (
                                        <span>Download for Free</span>
                                    ) : (
                                        <span>Free Sample</span>
                                    )}
                                </Button>
                            </Link>{' '}
                            {showCredits && (
                                <Link to={'/use-credit/' + id}>
                                    <Button
                                        variant="success"
                                        size={BUTTON_SIZE}
                                        className={css.xsMargin}
                                    >
                                        Download for {singularPlural('credit', credits, true)}
                                    </Button>
                                </Link>
                            )}{' '}
                            {!isFree(price, userCountry) && showPrice && (
                                <Link to={'/book/' + id + '?download=true'}>
                                    <Button
                                        variant="success"
                                        size={BUTTON_SIZE}
                                        className={css.xsMargin}
                                    >
                                        Download for {parsePrice(price, userCountry)}
                                    </Button>
                                </Link>
                            )}{' '}
                            {!isFree(price, userCountry) && showPrice && (
                                <Link to={'/book/' + id + '?gift=true'}>
                                    <Button
                                        style={{ backgroundColor: '#8565c4' }}
                                        variant="success"
                                        size={BUTTON_SIZE}
                                        className={css.xsMargin}
                                    >
                                        Gift for {parsePrice(price, userCountry)}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    ) : null}

                    {paperbackDetails ? (
                        <div>
                            <BuyOnAmazonButton
                                text={'Buy Printed Book on Amazon'}
                                url={paperbackDetails.url}
                            />
                        </div>
                    ) : null}
                </Col>
            </Row>
        </div>
    );
};
