import React, { useContext } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import firebaseUiConfig from '../../assets/Firebase/uiConfig';
import strings from '../../assets/text/strings';
import { FirebaseContext } from '../../components/Firebase';
import css from './index.module.scss';

export default () => {
    const firebase = useContext(FirebaseContext);
    if (!firebase || !firebase.auth) {
        return <></>;
    }

    firebaseUiConfig.signInSuccessUrl = '/';

    return (
        <Container>
            <br />
            <Row className="justify-content-md-center">
                <Col xs="12" md="8" lg="6" style={{ textAlign: 'center' }}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={css.title + ' mb-2'}>{strings.SignInHeader}</Card.Title>
                            <Card.Subtitle className={css.subTitle + ' mb-3 text-muted'}>{strings.SignInOptions}</Card.Subtitle>
                            <div style={{ textAlign: 'center' }}>
                                <FirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth} />
                            </div>
                        </Card.Body>
                        <Card.Footer className={css.privacyTitle + ' text-muted'}>{strings.NeverShareInformation}</Card.Footer>
                    </Card>
                </Col>
            </Row>
            <br />
        </Container>
    );
};
