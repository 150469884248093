import axios from 'axios';
import { SavePaymentProps } from '../../Types';

export default async (authToken: string, txnDetails: SavePaymentProps) => {
    const response = await axios.post(
        `${process.env.REACT_APP_FUNCTION_BASE_URL}/savePaymentTxn`,
        txnDetails,
        {
            headers: {
                'Context-type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        }
    );

    if (response.status !== 204) {
        console.error(response);
        throw new Error('Unable save payment transaction');
    }
    return response.data;
};
