import strings from '../../assets/text/strings';
import {
    DigitalBookProps,
    DownloadProgressProps,
    DownloadUrlProps,
    TimedAlertProps,
} from '../../Types';
import downloadFromUrl from '../../utils/files/downloadFromUrl';
import getSampleUrl from '../../utils/firebase/getSampleUrl';

interface MyProps {
    product: undefined | DigitalBookProps;
    authToken: string;
    setStatus: (status: TimedAlertProps) => void;
    setProcessingDownload: (status: boolean) => void;
    updateProgress: (progress: DownloadProgressProps) => void;
}

export default async ({
    product,
    authToken,
    setStatus,
    setProcessingDownload,
    updateProgress,
}: MyProps) => {
    if (!product) {
        setStatus({
            title: strings.BookNotFoundTitle,
            type: 'warning',
            message: strings.BookNotFoundText,
        });
        return;
    }

    setStatus({
        title: strings.Downloading,
        type: 'info',
        message: strings.DownloadingPleaseWait,
    });

    setProcessingDownload(true);
    let fileUrlData: DownloadUrlProps = {
        url: '',
        saveAs: '',
    };
    try {
        fileUrlData = await getSampleUrl(authToken, product.id, 'sample');
    } catch (e) {
        setStatus({
            title: strings.SomethingWentWrong,
            type: 'danger',
            message: strings.UnableToGetDownloadDetails,
            showClose: true,
        });
        setProcessingDownload(false);
        return;
    }

    window.open(fileUrlData.url, '_blank');
    downloadFromUrl({
        srcUrl: fileUrlData.url,
        dstFilename: fileUrlData.saveAs || 'BrightBeginningBook.pdf',
        onLoaded: async () => {
            setStatus({
                title: strings.SampleSuccessTitle,
                type: 'success',
                message: strings.SampleSuccessText,
                showClose: true,
                timeout: 10,
            });
            setProcessingDownload(false);
        },
        updateProgress,
    });
};
